<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>
    <div v-if="!showIntro" class="top-menu" :class="{'light' : current && ((!current.menuColor || !current.menuColor.length) || (current.menuColor.length && current.menuColor[0] != 'indigo'))}">
      <ul>
        <template v-for="(item, i) in slides">
          <li :key="'key-menu-' + i" v-if="item.menuTitle && (item.showOnTopMenu || item.parent)">
            <a href="#" v-if="item.showOnTopMenu" @click.stop.prevent="handleMenu(item, i)"
               :class="{ 'active' : slides[currentSlide] && slides[currentSlide].menuTitle == item.menuTitle }">{{ item.menuTitle }}</a>
            <div class="top-menu-sub-list"
                 v-if="dropdownMenu.show && dropdownMenu.list.length > 0 && dropdownMenu.index == i">
              <div class="d-flex justify-content-end">
                <span class="menu-close-icon" @click="closeMenu()">
                  <img :src="require('@/assets/images/cancel-white.svg')" alt="">
                </span>
              </div>
              <ul>
                <li v-for="(ditem, index) in dropdownMenu.list" :key="'sub-menu-' + index">
                  <a @click="handleSubMenu(ditem.realIndex)" v-text="ditem.menu_title"
                     :class="{'active': slides[currentSlide] && parseInt(slides[currentSlide].id) === parseInt(ditem.id)}"></a>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="vertStripe"></div>
    <div class="app-inner" v-if="!slideWithPassword">
      <div class="introLayer" v-if="intro" :class="{ 'hidden' : !showIntro }">
        <div class="i2-circle" :class="{ 'hidden' : !showIntro }">
          <span></span>
        </div>
        <video :poster="baseUrl + intro.video_cover" muted @loadedmetadata="$route.params.id !== undefined && handleIntroVideo" @ended="currentSlide === 0 && changeSlides()" autoplay class="slide2-intro-video show-video"
               ref="$video" id="intro-video" v-if="intro.video" playsinline
               :src="baseUrl + intro.video"></video>
        <button v-if="!isVideoPlaying" id="play-intro" class="slide2-video-play"><img
          :src="require('@/assets/images/play.svg')" alt=""></button>
        <button @click="triggerChange()" class="btn text-white slide2-video-skip">Skip Intro <img
          src="@/assets/images/intro-arrows.svg" alt="">
        </button>
        <div class="introLayerContent" @click="handleIntroVideo">
          <h1 v-if="!isVideoPlaying"
              class="text-white display-3 text-uppercase font-weight-bolder text-center slide2-intro-title"
              v-html="intro.title"></h1>
          <!-- <div class="arrowDown">
            <img src="@/assets/images/arrow.svg" />
          </div> -->
        </div>
      </div>
      <div class="slides-wrapper intro2" :class="{ 'hidden' : showIntro }" >
        <template v-for="(item, index) in slides">
          <!-- {{ $window.console.log(item) }} -->
          <div
            :key="'a-slide' + index" class="a-slide"
            :class="{
              'animate' : (animate && currentSlide == index),
              'active': currentSlide == index,
              'prevSlide' : index < currentSlide,
              'initialSlide': toSlide == index,
              'typeTwo': item.type == 2,
              'uhdTypeTwo': item.type == 2 && item.animation,
              'lastSimpleSlide': ( item.type == 1 && slides[(parseInt(index) + 1)] && slides[(parseInt(index) + 1)].type == 2),
              'type-three-custom': item.type == 3,
              'type-single-image-slide': item.type == 5,
              'a-slide-type-6': item.type == 6,
              ['a-slide-' + item.type]: [item.type]
            }"
            :style="{
              backgroundImage: [9,1,3,4,5,6,20].includes(item.type) ? ('url(' + baseUrl + ([3,4,5,6,9].includes(item.type) ? item.img : item.overlay) + ')') : ''
            }"
            @click.self="triggerChange()">
            <custom-slide v-if="item.type === 4" :item="item" :baseUrl="baseUrl" @triggerChange="triggerChange()"></custom-slide>
            <div v-if="item.type === 1" class="content pb-6 mb-6" :class="{ 'animate' : animate }">
              <div class="d-flex flex-column">
                <div class="d-block">
                  <div class="h1"><span>{{ item.title }}</span></div>
                  <div class="h1"><span>{{ item.subtitle }}</span></div>
                  <div class="h1" v-if="item.thirdTitle"><span>{{ item.thirdTitle }}</span></div>
                  <button v-if="item.explanation" @click.stop.prevent="panels.explanation = true"
                          class="h1 btn-explanation btn btn-lg btn-block bg-black text-uppercase text-white">Mehr
                    erfahren
                    <img class="arrow-l" :src="require('@/assets/images/arrow-left-l.svg')" alt="">
                  </button>
                </div>
              </div>
            </div>
            <div v-if="item.type === 1 && item.img" class="slide-type-1-img-holder">
              <img v-if="item.img" :src="(baseUrl + item.img)" alt="">
            </div>
            <div v-else-if="item.type === 2" class="secondContent" :class="{ 'uhdActive': item.animation }">
              <h1 v-text="item.title"></h1>
              <div class="imageHolder" :class="{ anim: item.images.badge }" v-if="item.images && !item.images.multiImage">
                <div class="imageBadge" v-if="item.images.badge">
                  <span>SD</span>
                  <span>UHD</span>
                </div>
                <div class="imageCircleProg" v-if="item.images.progressbar">
                  <radial-progress-bar
                    :diameter="progressDiameter"
                    :completed-steps="progressSteps"
                    :total-steps="100"
                    :startColor="pStartColor"
                    :stopColor="pStopColor"
                    :innerStrokeColor="'#E6E6E6'"
                    :strokeWidth="progressBarConfig.pStrokeWidth"
                    :innerStrokeWidth="progressBarConfig.pInnerStrokeWidth"
                    :animateSpeed="progressSpeed">
                    <div class="circleContent">
                      <span data-counters :data-count-target="!item.animation ? 10 : 35" v-text="progressNr"></span>
                      <p v-text="!item.animation ? 'Stunden' : 'Minute'"></p>
                    </div>
                  </radial-progress-bar>
                </div>
                <div class="imgWrap" :class="{ 'uhdImg': item.animation }">
                  <img :src="baseUrl + item.images.first"/>
                  <img :src="baseUrl + item.images.second"/>
                </div>
              </div>
              <div v-else class="imageHolder multiImage">
                <div class="firstIn">
                  <span>
                    <img :src="baseUrl + item.images.first"/>
                  </span>
                  <div class="secondIn">
                    <span>
                      <img :src="baseUrl + item.images.second"/>
                    </span>
                    <div class="thirdIn">
                      <span>
                        <img :src="baseUrl + item.images.third"/>
                      </span>
                      <div class="fourthIn">
                        <span>
                          <img :src="baseUrl + item.images.fourth"/>
                        </span>
                        <div class="fifthIn">
                          <span>
                            <img :src="baseUrl + item.images.fifth"/>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.type == 2" class="i2-inner-circle inverse" :class="{ 'animate' : currentSlide > 0 }"></div>
            <div class="i2-inner-circle" v-if="![3,4,5,6,7,9,20].includes(item.type)" :class="{ 'animate' : currentSlide > 0 }"></div>

            <div class="jumbo" v-if="item.type == 3">
              <h1 v-if="item.title" v-html="item.title"></h1>
              <h2 :class="{ 'title-with-progressbar' : item.progressBar }" v-if="item.subTitle"
                  v-html="item.subTitle"></h2>
            </div>
            <span class="toggleTypeTwo" v-if="item.type == 2" @click.stop="handleAnimation(item)"
                  :class="{ 'triggered': item.animation }">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" v-if="!item.animation">
                <g id="plus" transform="translate(0.006 0.006)">
                  <g id="Asset" transform="translate(-0.006 -0.006)">
                    <rect id="Rectangle_275" data-name="Rectangle 275" width="60" height="60" fill="none"/>
                  </g>
                  <g id="Icons" transform="translate(3.74 3.771)">
                    <path id="Path_260" data-name="Path 260"
                          d="M55.467,29H31.859V5.415a1.416,1.416,0,0,0-2.833,0V29H5.417a1.415,1.415,0,1,0,0,2.83H29.026V55.407a1.416,1.416,0,0,0,2.833,0V31.826H55.467a1.415,1.415,0,1,0,0-2.83Z"
                          transform="translate(-4.001 -4)" fill="#fff"/>
                  </g>
                </g>
              </svg>
              <svg v-else id="Icons" xmlns="http://www.w3.org/2000/svg" width="31.586" height="1.692"
                   viewBox="0 0 31.586 1.692">
                <path id="Path_346" data-name="Path 346"
                      d="M34.741,31.692a.846.846,0,0,0,0-1.692H4.847a.846.846,0,1,0,0,1.692Z"
                      transform="translate(-4.001 -30)" fill="#fff"/>
              </svg>
            </span>
            <div class="splashSlides" v-if="item.type === 3 && item.splashSlideBox">
              <div class="splashSlideContent">
                <div class="h2 pb-2" v-html="item.splashSlideBox.title"></div>
                <p v-html="item.splashSlideBox.text"></p>
              </div>
              <div class="mock">
                <img :src="baseUrl + item.splashSlideBox.img"/>
                <span v-html="item.splashSlideBox.sticker"></span>
              </div>
            </div>
            <div v-if="item.type === 3 && item.hasPopup && item.hasPopup.text !== ''">
              <a @click.stop="openIframePopup(item.hasPopup)" class="hasPopup cursor-pointer">
                <p v-text="item.hasPopup.text"></p>
                <figure>
                  <img :src="baseUrl + item.hasPopup.imgIcon"/>
                </figure>
              </a>
            </div>
            <div
              v-if="item.type === 5 || item.type === 9"
              class="a-slide-up-cont"
              :class="{
                'main': item.contentPosition === 'center'
              }"
            >
            <div v-if="item.type !== 9" class="i2-inner-circle" :class="{ 'animate' : currentSlide > 0 }"></div>
              <h1 v-if="item.title" :class="{'text-primary': item.type === 9}" v-html="item.title"></h1>
              <h2 v-if="item.subTitle" v-html="item.subTitle"></h2>
            </div>
            <div
              v-if="item.type === 5 || item.type === 9"
              class="singleImageContent a-slide-down-cont"
              :class="[{
              'halfSpace': item.contentPosition !== 'center' && item.subTitle,
              'hasSlides': item.hasSlides
              }]"
            >
              <ul class="listImage" v-if="item.type === 5 && item.sponsImg && item.sponsImg.length > 0">
                <template v-for="(itm, i) in item.sponsImg">
                  <li :key="'spimg' + i">
                    <img v-if="itm.img" class="sponsBy" :src="(baseUrl + itm.img)" alt="">
                    <span v-if="itm.title" v-text="itm.title"></span>
                  </li>
                </template>
              </ul>
              <SlideVideos
              v-if="item.type === 9 && item.slide_video_box.length"
              :videos="item.slide_video_box"
              :baseUrl="baseUrl"
              @toggleVideo="openVideo"/>
            </div>
            <anschluss-slide v-if="item.type === 6" :item="item"></anschluss-slide>
            <explanation :key="'type-7-ismet-balla-' + item.id" v-if="currentSlide == index && item.type == 7 && item.explanation" :title="item.title" :id="item.explanation" :page="true"></explanation>
            <marketingSlide @changeSlides="changeSlides" v-if="item.type === 20" :baseUrl="baseUrl" :item="item"></marketingSlide>

          </div>
        </template>
      </div>
    </div>
    <div class="iframe-popup" @click.self="iframePopUp = false" v-if="iframePopUp">
      <div class="iframe-content-wrapper">
        <iframe :src="iframePopUp.external_link" frameborder="0"></iframe>
        <button @click.stop="iframePopUp = false" class="close btn btn-link btn-sm">
          <img src="@/assets/images/cancel.svg" alt="">
        </button>
      </div>
    </div>
    <SlideVideosPlayer
      v-if="slideVideo"
      :slideVideo="slideVideo"
      :baseUrl="baseUrl"
      @toggleVideo="openVideo" />
    <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="true"
      :intro="intro"
      v-on:changeSlides="triggerChange"></sideMenu>
    <infoWrapper
      :style="{ zIndex : current && current.explanation ? 102 : 1 }"
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <explanation
      :id="(current && current.type != 7 && current.explanation)? current && current.explanation : null"
      v-if="current && current.type === 1 && current.explanation && panels.explanation"
      :title="current.title"
      :subtitle="current.subtitle"
      :thirdTitle="current.thirdTitle"
      v-on:togglePanel="togglePanel"></explanation>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import explanation from '@/components/explanation'
import backToHomeButton from '@/components/backToHomeButton'
// import VueCircle from 'vue2-circle-progress/src/index.vue'
import anschlussSlide from '@/components/intro11/anschluss-slide'
import RadialProgressBar from 'vue-radial-progress'
import videoWrapper from '@/components/videoWrapper'
import PasswordModal from '@/components/passwordModal'
import CustomSlide from '@/components/customSlide'
import marketingSlide from '@/components/marketingSlide'
import SlideVideos from '@/components/intro16-hybrid/SlideVideos'
import SlideVideosPlayer from '@/components/intro16-hybrid/SlideVideosPlayer'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  components: {
    PasswordModal,
    infoWrapper,
    sideMenu,
    RadialProgressBar,
    backToHomeButton,
    bottomVideos,
    explanation,
    videoWrapper,
    CustomSlide,
    anschlussSlide,
    marketingSlide,
    SlideVideos,
    SlideVideosPlayer
  },
  data () {
    return {
      slideWithPassword: null,
      backToHomeButtonText: 'Title',
      msg: 'Telekom 5G',
      currentSlide: 0,
      toSlide: 0,
      animate: false,
      showHome: true,
      circleDiameter: 730,
      circleShadows: false,
      showLoader: false,
      current: false,
      showVideos: false,
      moreInfo: false,
      showIntro: true,
      isAnimating: false,
      showInfoWrapper: true,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'explanation': false,
        'videoWrapper': false
      },
      progressSteps: 0,
      progressSpeed: 3000,
      progressNr: 0,
      pStrokeWidth: 18,
      pInnerStrokeWidth: 21,
      pStartColor: '#E20074',
      pStopColor: '#E20074',
      showInfoBox: false,
      infoBoxData: null,
      isVideoPlaying: true,
      iframePopUp: null,
      xDown: null,
      yDown: null,
      tippDialog: null,
      dropdownMenu: {
        index: null,
        list: [],
        show: false
      },
      slideVideo: false
    }
  },
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage']),
    progressDiameter () {
      return window.innerWidth < 768 ? 150 : 300
    },
    progressBarConfig () {
      return {
        diameter: window.innerWidth < 768 ? 150 : 300,
        pStrokeWidth: window.innerWidth < 768 ? 10 : 18,
        pInnerStrokeWidth: window.innerWidth < 768 ? 12 : 21
      }
    }
  },
  watch: {
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    },
    'panels.explanation' (nv) {
      if (this.current.explanation) { if (nv) { this.showInfoBox = true } else this.showInfoBox = false }
    },
    current (nv, ov) {
      if (nv && nv.has_password) {
        this.current = null
        this.slideWithPassword = nv
        this.showHome = true
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
      }
      if (nv && nv.type === 4) {
        this.initEvents(false)
      } else this.initEvents(true)
    }
  },
  created () {
    window.addEventListener('resize', this.resizeEventHandler)
    window.dispatchEvent(new Event('resize'))
  },
  mounted () {
    let $this = this
    this.searchSlides(2).then(resp => {
      if (resp) $this.handleInfoBox($this.introInfoPage)

      this.$nextTick(() => {
        var routeID = null
        if (this.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        // debugger
        if (routeID && routeID <= Object.keys(this.slides).length) { this.showIntro = false; this.hideIntro(); this.changeSlides(routeID) } else {
          this.handleIntroVideo(true)
          this.currentSlide = 0
          this.resizeEventHandler()
        }

        $this.$nextTick(() => {
          $this.initEvents(true)
        })

        document.querySelector('.slides-wrapper').addEventListener('wheel', (e) => {
          var dir = Math.sign(e.deltaY)
          // debugger
          if (!this.isAnimating) {
            if (dir === -1) {
              $this.triggerChange('r')
            } else {
              $this.triggerChange('l')
            }
          }
          e.preventDefault()
          e.stopPropagation()
          return false
        })
      })
    })

    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeEventHandler)
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    getCircleDiameter () {
      return (window.innerWidth * 0.8 > 576) ? 730 : window.innerWidth * 1.4
    },
    resizeEventHandler () {
      this.circleDiameter = this.getCircleDiameter()
    },
    triggerChange (id) {
      let cmItem = this.slides[this.currentSlide]
      if (this.isAnimating) {
        return false
      }
      this.isAnimating = true
      if (id !== undefined) this.changeSlides(id)
      else this.changeSlides()

      if (cmItem && cmItem.animation !== undefined) {
        setTimeout(() => {
          cmItem.animation = false
        }, 500)
      }
    },
    changeSlides (id) {
      // debugger
      let self = this // << this not working inside setTimeout

      if (self.currentSlide === 0) {
        if (id === undefined) {
          self.toSlide = (this.currentSlide + 1)
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') self.toSlide = (this.currentSlide + 1)
          else self.toSlide = (this.currentSlide - 1)
        } else self.toSlide = id
      } else {
        self.toSlide = 0
      }

      if (this.showIntro) this.hideIntro(false)
      this.loader()
      setTimeout(() => {
        // debugger
        if (id === undefined) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') self.currentSlide++
          else self.currentSlide--
        } else self.currentSlide = id

        if (self.slides[self.currentSlide] === undefined) {
          self.currentSlide = 0
          self.handleIntroVideo(true)
          self.isAnimating = false
          return
        }

        self.circleDiameter = self.getCircleDiameter()
        self.animate = true
        self.showLoader = false
        self.circleShadows = true
        self.current = self.slides[self.currentSlide]

        setTimeout(() => {
          // debugger
          if (self.slides[self.currentSlide].images &&
              self.slides[self.currentSlide].images.progressbar !== undefined) {
            // self.progressNr = 10
            // self.progressSteps = 75
            self.slides[self.currentSlide].animation = true
            self.handleAnimation(self.slides[self.currentSlide])
          }
          if (this.$route.params?.id) { this.addLoading(false) }
          self.isAnimating = false
        }, (id && (id === 'l' || id === 'u')) ? 2000 : 500)
      }, self.currentSlide === 0 ? 500 : ((id && (id === 'l' || id === 'u')) ? 1000 : 500))
    },
    loader () {
      this.animate = false
      this.current = false
      this.circleDiameter = 0
      this.circleShadows = false
      this.showLoader = true
    },
    closePopup () {
      this.showVideos = false
      this.moreInfo = false
      this.panels['explanation'] = false
    },
    // handleIntroVideo (isFirst = false) {
    //   // debugger
    //   this.showIntro = true
    //   if (!isFirst) this.triggerChange(null, 0)
    // },
    handleIntroVideo () {
      this.showIntro = true
      this.isVideoPlaying = false
      let video = this.$refs.$video

      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          this.isVideoPlaying = true
        }
      } else {
        this.changeSlides()
      }
    },
    hideIntro (changeSlide = true) {
      this.showIntro = false
      this.showLoader = true
      this.isAnimating = false
    },
    togglePanel (panelName, value) {
      if (panelName === 'infoWrapper' && this.current.explanation && this.panels.explanation && !this.panels.infoWrapper) { this.panels.infoWrapper = true; return } else if (panelName === 'infoWrapper' && this.current.explanation && this.panels.explanation && this.panels.infoWrapper) { this.panels.infoWrapper = false; return }

      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    animateCounters (obj, start, end, duration, append = null) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        if (append) obj.innerHTML += '%'
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    },
    toggleAnim (speed) {
      let counters = document.querySelectorAll('[data-counters]')
      counters.forEach((item, index) => {
        this.animateCounters(item, 0, item.getAttribute('data-count-target'), speed, null)
      })
    },
    handleAnimation (item) {
      item.animation = !item.animation
      if (item.images.progressbar !== undefined) {
        this.progressSpeed = 500
        this.progressSteps = 0
        setTimeout(() => {
          this.progressSpeed = item.animation ? 1000 : 2000
          this.toggleAnim(this.progressSpeed)
          this.progressSteps = 75
          this.pStartColor = item.animation ? '#E20074' : '#1063AD'
          this.pStopColor = item.animation ? '#E20074' : '#1063AD'
        }, 500)
      }
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      if (this.current && this.current.explanation) {
        this.showInfoBox = false
      } else this.showInfoBox = data?.content_elements?.length > 0
    },
    openIframePopup (item) {
      if (item.is_iframe) this.iframePopUp = item
      else window.open(item.external_link)
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      let $this = this
      if (!this.xDown || !this.yDown || $this.isAnimating) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          $this.changeSlides('l')
        } else {
          $this.changeSlides('r')
        }
      } else {
        if (yDiff > 0) {
          $this.changeSlides('u')
        } else {
          $this.changeSlides('d')
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    wheelFunction (e) {
      let $this = this
      var dir = Math.sign(e.deltaY)

      if (!$this.isAnimating) {
        if (dir === -1) {
          $this.changeSlides('r')
        } else {
          $this.changeSlides('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    initEvents (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('touchstart', $this.handleTouchStart, false)
        ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        // ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    removeWheelEvent (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        // ele.addEventListener('touchstart', $this.handleTouchStart, false)
        // ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        // ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        // ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    handleMenu (navItem, index) {
      if (this.dropdownMenu.show) {
        this.dropdownMenu.show = false
        this.changeSlides(index)
      } else {
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
        this.dropdownMenu.index = index
        for (let key in this.slides) {
          // console.log(parseInt(this.slides[key].parentId) + '-' + parseInt(navItem.id), this.slides[key]);
          if (this.slides[key] && parseInt(this.slides[key].parentId) === parseInt(navItem.id) && parseInt(this.slides[key].parentId) !== parseInt(this.slides[key].id)) {
            let item = this.slides[key]
            item.realIndex = parseInt(key)
            this.dropdownMenu.list.push(item)
          }
        }
        if (this.dropdownMenu.list.length > 0) {
          this.dropdownMenu.show = true
        } else {
          this.changeSlides(index)
        }
      }
    },
    closeMenu () {
      this.dropdownMenu = {
        index: null,
        list: [],
        show: false
      }
    },
    handleSubMenu (index) {
      this.changeSlides(index)
      this.closeMenu()
    },
    openVideo (video) {
      this.slideVideo = video || false
    }
  }
}
</script>
